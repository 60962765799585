import React, { useState, useEffect, useRef, useCallback } from "react"
import { Link } from "gatsby"
import { Scrollspy } from "@makotot/ghostui"
import { Navbar, Nav, Row, Col } from "react-bootstrap"
import scrollTo from "gatsby-plugin-smoothscroll"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const Header = ({ aboutRef, skillsRef, portfolioRef, contactRef }) => {
  /**********************/
  /*EXPANDED MENU TOGGLE*/
  /**********************/
  const [expanded, setExpanded] = useState(false)
  const setNavExpanded = () => {
    setExpanded(!expanded)
  }

  const closeNav = () => {
    setExpanded(false)
  }

  /********************/
  /*SET UP CANVAS REFS*/
  /********************/
  //State
  const [background, setBackground] = useState(null)

  //Assign ref to state
  const c1Ref = useCallback(node => {
    if (node !== null) {
      setBackground(prevState => ({
        ...prevState,
        c1: node,
        ctx1: node.getContext("2d")
      }))
    }
  }, [])

  //Assign another ref to state
  const c2Ref = useCallback(node => {
    if (node !== null) {
      setBackground(prevState => ({
        ...prevState,
        c2: node,
        ctx2: node.getContext("2d")
      }))
    }
  }, [])

  /********************/
  /*SET UP CANVAS REFS*/
  /********************/
  let c1 = useRef(null),
    ctx1 = useRef(null),
    c2 = useRef(null),
    ctx2 = useRef(null),
    parts = useRef([]),
    heroRef = useRef(true),
    initFlagRef = useRef(false),
    resizeFlagRef = useRef(false)

  /*******************************************************/
  /*MANAGE HEADER SIZE, TRIGGER EFFECT OF BOKEH ON RESIZE*/
  /*******************************************************/
  let defaultWidth = 1920
  let defaultHeight = 1080

  if (typeof window !== `undefined`) {
    defaultHeight = window.innerHeight
    defaultWidth = window.innerWidth
  }

  const [windowSize, setWindowSize] = useState({
    width: defaultWidth,
    height: defaultHeight
  })

  const handleResize = () => {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight
    })
  }

  useEffect(() => {
    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  }, [windowSize])

  /***************************/
  /*SET UP FOR BOKEH**********/
  /***************************/

  //helper
  const rand = (min, max) => {
    return Math.random() * (max - min) + min
  }

  //helper
  const hsla = (h, s, l, a) => {
    return "hsla(" + h + "," + s + "%," + l + "%," + a + ")"
  }

  //toggler
  const [heroPlaying, setHeroPlaying] = useState(true)
  const handleStaticClick = () => {
    setHeroPlaying(!heroPlaying)
    heroRef.current = !heroRef.current
  }

  /*******/
  /*MAGIC*/
  /*******/
  useEffect(() => {
    if (background !== null && typeof window !== `undefined`) {
      c1.current = background.c1
      ctx1.current = background.c1.getContext("2d")
      c2.current = background.c2
      ctx2.current = background.c2.getContext("2d")

      let sizeBase, opt, hue, count
      const twopi = Math.PI * 2

      const create = () => {
        sizeBase = window.innerWidth + window.innerHeight
        count = Math.floor(sizeBase * 0.065)
        hue = 285
        opt = {
          radiusMin: 0.1,
          radiusMax: sizeBase * 0.032,
          blurMin: 10,
          blurMax: sizeBase * 0.08,
          hueMin: hue,
          hueMax: hue + 100,
          saturationMin: 70,
          saturationMax: 100,
          lightnessMin: 40,
          lightnessMax: 70,
          alphaMin: 0.01,
          alphaMax: 0.55
        }

        ctx1.current.clearRect(0, 0, window.innerWidth, window.innerHeight)
        ctx1.current.globalCompositeOperation = "lighter"
        while (count--) {
          let radius = rand(opt.radiusMin, opt.radiusMax),
            blur = rand(opt.blurMin, opt.blurMax),
            x = rand(0, window.innerWidth),
            y = rand(0, window.innerHeight),
            innerhue = rand(opt.hueMin, opt.hueMax),
            saturation = rand(opt.saturationMin, opt.saturationMax),
            lightness = rand(opt.lightnessMin, opt.lightnessMax),
            alpha = rand(opt.alphaMin, opt.alphaMax)
          ctx1.current.shadowColor = hsla(
            innerhue,
            saturation,
            lightness,
            alpha
          )
          ctx1.current.shadowBlur = blur
          ctx1.current.beginPath()
          ctx1.current.arc(x, y, radius, 0, twopi)
          ctx1.current.closePath()
          ctx1.current.fill()
        }
        parts.current.length = 0
        for (
          let i = 0;
          i < Math.floor((window.innerWidth + window.innerHeight) * 0.03);
          i++
        ) {
          parts.current.push({
            radius: rand(1, sizeBase * 0.03),
            x: rand(0, window.innerWidth),
            y: rand(0, window.innerHeight),
            angle: rand(0, twopi),
            vel: 0.2,
            tick: rand(0, 400000)
          })
        }
      }
      const loop = () => {
        ctx2.current.clearRect(0, 0, window.innerWidth, window.innerHeight)
        ctx2.current.globalCompositeOperation = "source-over"
        ctx2.current.shadowBlur = 0
        ctx2.current.drawImage(c1.current, 0, 0)

        let i = parts.current.length
        ctx2.current.shadowBlur = 25
        ctx2.current.shadowColor = "#ff00f7"
        while (i--) {
          let part = parts.current[i]
          part.x += Math.cos(part.angle) * part.vel
          part.y += Math.sin(part.angle) * part.vel
          part.angle += rand(-0.05, 0.05)
          ctx2.current.beginPath()
          ctx2.current.arc(part.x, part.y, part.radius, 0, twopi)
          ctx2.current.fillStyle = hsla(
            0,
            0,
            100,
            0.075 + Math.cos(part.tick * 0.015) * 0.06
          )
          ctx2.current.fill()
          if (part.x - part.radius > window.innerWidth) {
            part.x = -part.radius
          }
          if (part.x + part.radius < 0) {
            part.x = window.innerWidth + part.radius
          }
          if (part.y - part.radius > window.innerHeight) {
            part.y = -part.radius
          }
          if (part.y + part.radius < 0) {
            part.y = window.innerHeight + part.radius
          }

          part.tick++
        }

        resizeFlagRef.current = false
      }

      const resize = () => {
        resizeFlagRef.current = true
        c1.current.width = c2.current.width = window.innerWidth
        c1.current.height = c2.current.height = window.innerHeight
        create()
      }

      const init = () => {
        resize()
        create()
      }

      /*************************/
      /*SUPER IMPORTANT*********/
      /*INFINITE LOOP OTHERWISE*/
      /*************************/
      if (heroPlaying) {
        let timerId

        const f = () => {
          if (heroRef.current && !initFlagRef.current) {
            init()
            loop()
            initFlagRef.current = true

            window.addEventListener("resize", () => {
              resize()
              loop()
            })
          } else {
            loop()
          }
          timerId = requestAnimationFrame(f)
        }

        timerId = requestAnimationFrame(f)
        return () => cancelAnimationFrame(timerId)
      }
    }
  }, [heroPlaying, background])

  const scrollSpyData = [
    {
      name: "About",
      id: "#about",
      ref: aboutRef
    },
    {
      name: "Skills",
      id: "#skills",
      ref: skillsRef
    },
    {
      name: "Portfolio",
      id: "#portfolio",
      ref: portfolioRef
    },
    {
      name: "Contact",
      id: "#contact",
      ref: contactRef
    }
  ]

  return (
    <>
      <Navbar
        className="fixed-top py-3"
        id="mainNav"
        expand="lg"
        onToggle={setNavExpanded}
        expanded={expanded}
      >
        <div className="container-fluid px-3 px-md-5">
          <div className="d-inline-flex align-items-baseline brand-container">
            <button
              className="navbar-brand js-scroll-trigger"
              tabIndex="0"
              onClick={() => {
                scrollTo("#page-top")
                closeNav()
              }}
            >
              Eric Alain
            </button>
            <div className="play-pause">
              <FontAwesomeIcon
                icon={heroPlaying ? ["fas", "eye-slash"] : ["fas", "eye"]}
                size="lg"
                onClick={handleStaticClick}
                onKeyDown={handleStaticClick}
                title={heroPlaying ? "Pause" : "Play"}
                role="button"
                aria-label="Make background static"
                aria-pressed={!heroPlaying}
                tabIndex={0}
              />
              <i
                className={`static-icon `}
                onClick={handleStaticClick}
                onKeyDown={handleStaticClick}
                role="button"
                aria-label="Make background static"
                tabIndex={0}
              ></i>
            </div>
          </div>
          <Navbar.Toggle aria-controls="navbarResponsive">
            <FontAwesomeIcon
              icon={expanded ? ["fas", "times"] : ["fas", "bars"]}
              size="lg"
            />
          </Navbar.Toggle>
          <Navbar.Collapse id="navbarResponsive">
            <Nav className="ml-4 ml-md-auto my-2 my-lg-0 px-">
              <Scrollspy
                className="navbar-nav px-1"
                sectionRefs={scrollSpyData.map(item => item.ref)}
                currentClassName="active"
                offset={-60}
              >
                {({ currentElementIndexInViewport }) => {
                  return scrollSpyData.map((item, i) => (
                    <li
                      key={i}
                      className={`nav-item ${
                        currentElementIndexInViewport === i ? "active" : ""
                      }`}
                    >
                      <Nav.Link
                        as="button"
                        tabIndex="0"
                        onClick={() => {
                          scrollTo(item.id)
                          closeNav()
                        }}
                      >
                        {item.name}
                      </Nav.Link>
                    </li>
                  ))
                }}
              </Scrollspy>
              <li className="nav-item">
                <Link to="/resume" className="nav-link">
                  Resume
                </Link>
              </li>
            </Nav>
          </Navbar.Collapse>
        </div>
      </Navbar>
      <header>
        <canvas ref={c1Ref} id="c1"></canvas>
        <canvas ref={c2Ref} id="c2"></canvas>
        <div className="container h-100">
          <div className="row h-100 align-items-center justify-content-center text-center">
            <div className="col-lg-10 align-self-end z-2">
              <h1 className="font-weight-bold">I'm Eric</h1>
              <hr className="divider my-4 mx-auto text-white" />
            </div>
            <div className="col-lg-8 align-self-baseline z-2">
              <p className="mb-3">
                Web Developer/Front End Engineer
              </p>
              <Row className="justify-content-center">
                <Col xs="auto" className="mb-3 px-1">
                  <a
                    href="https://www.linkedin.com/in/eric-alain-87897184/"
                    title="LinkedIn"
                    aria-label="LinkedIn"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FontAwesomeIcon
                      icon={["fab", "linkedin"]}
                      size="2x"
                      className="hero-icon"
                    />
                  </a>
                </Col>
                <Col xs="auto" className="mb-3  px-1">
                  <a
                    href="https://codepen.io/EricAlain/"
                    title="Codepen"
                    aria-label="Codepen"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FontAwesomeIcon
                      icon={["fab", "codepen"]}
                      size="2x"
                      className="hero-icon"
                    />
                  </a>
                </Col>
              </Row>
              <button
                className="hero-btn"
                onClick={() => {
                  scrollTo("#about")
                  closeNav()
                }}
              >
                Go on...
              </button>
            </div>
          </div>
        </div>
      </header>
    </>
  )
}
export default Header
