import React, { useRef } from "react"
import { useStaticQuery, graphql } from "gatsby"

//Font awesome
import { config, library } from "@fortawesome/fontawesome-svg-core"
import {
  faHtml5,
  faCss3,
  faSass,
  faJsSquare,
  faGithub,
  faMarkdown,
  faRedhat,
  faLinkedin,
  faReact,
  faNode,
  faCodepen
} from "@fortawesome/free-brands-svg-icons"
import {
  faEye,
  faEyeSlash,
  faTerminal,
  faPhone,
  faEnvelope,
  faBars,
  faTimes,
  faLayerGroup,
  faLowVision,
  faWandMagicSparkles,
  faAward,
  faSquare,
  faChartArea
} from "@fortawesome/free-solid-svg-icons"

//Components
import Layout from "../components/layout"
import Seo from "../components/Seo/Seo"
import Header from "../components/Header/Header"
import About from "../components/About/About"
import Skills from "../components/Skills/Skills"
import Portfolio from "../components/Portfolio/Portfolio"
import Contact from "../components/Contact/Contact"

config.autoAddCss = false
library.add(
  faEye,
  faEyeSlash,
  faWandMagicSparkles,
  faAward,
  faBars,
  faCss3,
  faEnvelope,
  faGithub,
  faJsSquare,
  faLayerGroup,
  faLinkedin,
  faLowVision,
  faMarkdown,
  faNode,
  faPhone,
  faReact,
  faRedhat,
  faSass,
  faTerminal,
  faTimes,
  faHtml5,
  faSquare,
  faCodepen,
  faChartArea
)

const IndexPage = ({ location, data }) => {
  const aboutRef = useRef(null)
  const skillsRef = useRef(null)
  const portfolioRef = useRef(null)
  const contactRef = useRef(null)

  const schemaQuery = useStaticQuery(
    graphql`
      query {
        meta: site {
          siteMetadata {
            description
            siteUrl
          }
        }
      }
    `
  )
  const schema = {
    "@context": "https://schema.org",
    "@type": "Organization",
    name: "Eric Alain",
    description: schemaQuery.meta.siteMetadata.description,
    url: schemaQuery.meta.siteMetadata.siteUrl,
    sameAs: [
      "https://www.facebook.com/eric.alain1",
      "https://www.linkedin.com/in/eric-alain-87897184/"
    ],
    address: {
      "@type": "PostalAddress",
      addressRegion: "Ottawa",
      addressCountry: "CA"
    }
  }
  return (
    <Layout location={location} data={data}>
      <Seo schemaMarkup={schema} />
      <Header
        aboutRef={aboutRef}
        skillsRef={skillsRef}
        portfolioRef={portfolioRef}
        contactRef={contactRef}
      />
      <About aboutRef={aboutRef} />
      <Skills skillsRef={skillsRef} />
      <Portfolio portfolioRef={portfolioRef} />
      <Contact contactRef={contactRef} />
    </Layout>
  )
}
export default IndexPage
