import React, { useState, useEffect } from "react"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useStaticQuery, graphql } from "gatsby"
import { encode } from "html-entities"
import GradientOverlay from "../Extras/GradientOverlay"

const Contact = ({ contactRef }) => {
  const infoQuery = useStaticQuery(
    graphql`
      query {
        info: dataJson {
          email
          phone
        }
      }
    `
  )
  const [info, setInfo] = useState({
    email: "email@domain.ca",
    phone: "555-555-5555"
  })
  useEffect(() => {
    setInfo({
      email: infoQuery.info.email,
      phone: infoQuery.info.phone
    })
  }, [infoQuery.info.email, infoQuery.info.phone])

  return (
    <section className="page-section" id="contact" ref={contactRef}>
      <GradientOverlay
        direction="right"
        colorClassName="grad-3"
        opacity="op-60"
      />
      <Container className="mb-5">
        <Row className="justify-content-center">
          <Col xs="8">
            <h2 className="mt-0 text-center">Get in touch</h2>
            <p className="subtitle text-center">Need to reach me?</p>
            <hr className="divider my-4 mx-auto" />
          </Col>
        </Row>
        <Row className="justify-content-center">
          {/*
		<Col xs="8" md="2" className="icon-tile text-center mb-5 mb-lg-0">
            <a className="d-block" href={`tel:${encode(info.phone)}`}>
              <FontAwesomeIcon
                icon={["fas", "phone"]}
                size="3x"
                className="mb-3"
              />
              <br />
              Phone
            </a>
          </Col>
		*/}
          <Col xs="8" md="2" className="icon-tile text-center">
            <a className="d-block" href={`mailto:${encode(info.email)}`}>
              <FontAwesomeIcon
                icon={["fas", "envelope"]}
                size="3x"
                className="mb-3"
              />
              <br />
              Email
            </a>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default Contact
