import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import GradientOverlay from "../Extras/GradientOverlay"
import { dateAgo } from "../../utils"

const About = ({ aboutRef }) => {
  const aboutQuery = useStaticQuery(
    graphql`
      query {
        allCloudinaryMedia(
          filter: { public_id: { glob: "eric-alain/about/*" } }
        ) {
          nodes {
            secure_url
          }
        }
      }
    `
  )
  const aboutImage = aboutQuery.allCloudinaryMedia.nodes[0].secure_url.replace(
    /^(.*?upload\/)(.*?)(\.jpg)$/,
    "$1c_scale,q_auto:eco,w_520/$2.webp"
  )

  const iconArr = [
    "HTML",
    "CSS",
    "Sass",
    "JS/TS",
    "JSX",
    "JSON",
    "PHP",
    "YAML",
    "Twig",
    "SQL",
    "Git",
    "MD"
  ]

  const renderTiles = arr => {
    return arr.map((item, i) => {
      return (
        <Col xs="4" xl="2" className="text-center mb-3" key={i}>
          <div className="about-languages">
            <p className="code-font">{item}</p>
          </div>
        </Col>
      )
    })
  }

  return (
    <section className="page-section" id="about" ref={aboutRef}>
      <GradientOverlay
        direction="left"
        colorClassName="grad-1"
        opacity="op-40"
      />
      <Container>
        <Row>
          <Col xs="12" md="2" className="mb-3 pl-0">
            <h2 className="mb-0">About</h2>
            <p className="subtitle">A bit about me</p>
            <hr className="d-md-none divider responsive text-start my-4"></hr>
          </Col>
          <Col
            xs="12"
            md="10"
            className="mb-3 pl-0 pt-0 pb-3 px-4 card box shadow-lg bg-none"
          >
            <sh />
            <div className="pb-3">
              <div className="image-container">
                <div className="image-overlay"></div>
                <img
                  className="about-image"
                  src={aboutImage}
                  alt="Eric laying on grass with his dog named Boo"
                  width="300"
                  height="450"
                />
              </div>
              <p>
                I'm a currently a Web Developer with about{" "}
                {dateAgo("2019-03-01")} of front end experience (and
                counting)! I'm passionate about building beautiful, responsive
                websites and smooth user interfaces. I also enjoy learning new
                coding languages, frameworks and further honing my skills.
              </p>
              <p>
                I absolutely love front end development and seek opportunities
                to deepen the breadth and scope of my career in this field.
              </p>
              <p>
                When possible I try to get involved in projects that push me to
                be a better developer, teach me new technologies or that benefit
                communities I care about.
              </p>
              <p>
                When I am not wrapped up in projects, I like to spend my time
                with my beautiful fiancée (you can check her out at{" "}
                <a
                  href="https://www.lifeoolongtheway.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  Life Oolong the Way
                </a>
                ), and our dog, Boo. I'm a coffeeholic, into weightlifting and
                also play high caliber Dodgeball.
              </p>
            </div>
            <Row className="mt-3">{renderTiles(iconArr)}</Row>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default About
