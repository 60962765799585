import { gsap, Power1 } from "gsap"

export const SkillsAnimation = ref => {
  //Handle fill of heading bar
  gsap.fromTo(
    ref?.children[1],
    {
      width: "0"
    },
    {
      width: "100%",
      duration: 0.5,
      ease: Power1.easeInOut
    }
  )
  //Handle large icon
  gsap.fromTo(
    ref?.children[0],
    {
      color: "rgba(180, 26, 144, 1)",
      transform: "scale(1.1)"
    },
    {
      color: "rgba(180, 26, 144, 0.06)",
      transform: "scale(1)",
      delay: 0.4,
      duration: 0.5,
      ease: Power1.easeInOut
    }
  )
  //Handle color of the text in the heading bar
  gsap.fromTo(
    ref?.children[1]?.children[0],
    {
      opacity: "0"
    },
    {
      opacity: "1",
      duration: 0.5,
      ease: Power1.easeInOut
    }
  )
  //Handle opacity of main text box
  gsap.fromTo(
    ref?.children[2],
    {
      opacity: "0"
    },
    {
      opacity: "1",
      delay: 0.6,
      duration: 0.5,
      ease: Power1.easeInOut
    }
  )
}
