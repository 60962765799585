import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

export const PortfolioTilesAnimations = (parent, arr) => {
  gsap.registerPlugin(ScrollTrigger)
  ScrollTrigger.matchMedia({
    // medium or larger
    "(min-width: 768px)": () => {
      gsap.set(arr.current, { clearProps: "all" })
      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: parent,
          start: "top +=35%",
          end: "+=25%",
          scrub: 1,
          snap: {
            snapTo: 1 / 3,
            duration: { min: 0.2, max: 3 },
            delay: 0
          }
        }
      })
      tl.from(arr.current[0], { opacity: 0, x: -100 }, 0)
        .from(arr.current[1], { opacity: 0, y: -100 }, 0)
        .from(arr.current[2], { opacity: 0, y: 100 }, 0)
        .from(arr.current[3], { opacity: 0, x: 100 }, 0)
    },

    // small
    "(max-width: 767px)": () => {
      gsap.set(arr.current, { clearProps: "all" })
      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: parent,
          start: "top +=50%",
          end: "+=25%",
          scrub: 1,
          snap: {
            snapTo: 1 / 3,
            duration: { min: 0.2, max: 3 },
            delay: 0.6
          }
        }
      })
      tl.from(arr.current[0], { opacity: 0, x: -25 }, 0.5)
        .from(arr.current[1], { opacity: 0, x: -25 }, 1)
        .from(arr.current[2], { opacity: 0, x: -25 }, 1.5)
        .from(arr.current[3], { opacity: 0, x: -25 }, 2)
    }
  }) 
}
