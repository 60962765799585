import React from "react"
import PropTypes from "prop-types"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Carousel from "react-bootstrap/Carousel"
import { cloudinaryOptimize } from "../../utils"

const PortfolioCarousel = ({ current, images, portfolioItems }) => {
  const carouselItems = portfolioItems.map(({ title, description, link }, i) => (
    <Carousel.Item key={i}>
      <Container>
        <Row>
          <Col xs="12" className="mt-2">
            <h2 className="title mb-0">{title}</h2>
            <hr className="mt-0 mb-3" />
          </Col>
          <Col xs="12" md="8" className="mb-3">
            <a href={link} target="_blank" rel="noreferrer">
              <img
                src={cloudinaryOptimize(images[i].secure_url, 900)}
                className="w-100"
                alt={title}
              />
            </a>
          </Col>
          <Col xs="12" md="4" className="mb-3">
            <p>{description}</p>
          </Col>
        </Row>
      </Container>
      <figure>
        <figcaption>
          <div className="counter">
            {i + 1} of {portfolioItems.length}
          </div>
        </figcaption>
      </figure>
    </Carousel.Item>
  ))

  return (
    <Carousel
      interval={null}
      keyboard={true}
      indicators={false}
      touch={true}
      defaultActiveIndex={current}
      slide={false}
    >
      {carouselItems}
    </Carousel>
  )
}

PortfolioCarousel.propTypes = {
  current: PropTypes.number.isRequired
}

export default PortfolioCarousel
