import React from "react"
import Col from "react-bootstrap/Col"
import Nav from "react-bootstrap/Nav"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const skillsIcons = [
  {
    eventKey: "front-end-development",
    title: "Front end development",
    iconName: ["fas", "layer-group"]
  },
  {
    eventKey: "accessibility",
    title: "Accessibility",
    iconName: ["fas", "low-vision"]
  },
  {
    eventKey: "node",
    title: "Node",
    iconName: ["fab", "node"]
  },
  {
    eventKey: "stylist",
    title: "Stylist",
    iconName: ["fas", "wand-magic-sparkles"]
  },
  {
    eventKey: "data-visualization",
    title: "Data visualization",
    iconName: ["fas", "chart-area"]
  },
  {
    eventKey: "french",
    title: "French",
    iconName: ["fas", "award"]
  }
]

const renderSkillsIcons = skillsIcons.map((skillsIcon, i) => {
  return (
    <Nav.Item key={i}>
      <Nav.Link eventKey={skillsIcon.eventKey} title={skillsIcon.title}>
        {
          <>
            <span className="sr-only">{skillsIcon.title}</span>{" "}
            <FontAwesomeIcon
              icon={skillsIcon.iconName}
              size="2x"
              className="tab-icon"
            />
          </>
        }
      </Nav.Link>
    </Nav.Item>
  )
})
const Icons = () => {
  return (
    <Col xs="12" sm="2" md="1" className="px-0">
      <Nav className="flex-row flex-sm-column justify-content-between">
        <>{renderSkillsIcons}</>
      </Nav>
    </Col>
  )
}

export default Icons
