import React, { useRef, useEffect } from "react"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import { PortfolioTilesAnimations } from "./PortfolioTilesAnimations"
import { cloudinaryOptimize } from "../../utils"

const PortfolioTiles = ({ parent, images, portfolioItems, handlePortfolioClick }) => {
  let refArr = useRef([])

  useEffect(() => {
    PortfolioTilesAnimations(parent.current, refArr)
  }, [parent])

  const renderPortfolioItems = portfolioItems.map(({ title }, i) => {
    return (
      <Col
        md="6"
        className="px-0"
        key={i}
        ref={el => (refArr.current = [...refArr.current, el])}
      >
        <button
          className="portfolio-box"
          aria-label={title}
          tabIndex="0"
          onClick={e => {
            e.preventDefault()
            handlePortfolioClick(i)
          }}
        >
          <img
            className="tile-img"
            alt={title}
            src={cloudinaryOptimize(images[i].secure_url, 900)}
          />
          <div className="portfolio-box-caption">
            <div className="project-name">{title}</div>
          </div>
        </button>
      </Col>
    )
  })

  return (
    <Row className="tile-box">
      <>{renderPortfolioItems}</>
    </Row>
  )
}

export default PortfolioTiles
